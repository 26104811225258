import { mapMutations } from 'vuex'

export const mixinWebsocket = {
  data() {
    return {
      ws: null,
    }
  },
  methods: {
    ...mapMutations(['setWsPeople']),
    // 初始websocket
    initWebsocket() {
      let baseURL = ''
      if (process.env.VUE_APP_API_URL) {
        baseURL = process.env.VUE_APP_API_URL.replace('https://', '')
      } else {
        baseURL = 'tycg-backend.skysharp.com.tw'
      }
      const wsURL = 'wss://' + baseURL + '/people'
      this.ws = new WebSocket(wsURL) // 建立連線
      this.ws.onopen = this.websocketonopen
      this.ws.error = this.websocketonerror
      this.ws.onmessage = this.websocketonmessage
      this.ws.onclose = this.websocketclose
      console.log('initWebsocket')
    },
    websocketonopen() {},
    websocketonerror(e) {
      console.error('連線失敗', e)
    },
    websocketonmessage(e) {
      // console.log('e', e)
      // 後端通知前端
      this.setWsPeople(e)
    },
    websocketclose() {},
  },
}
